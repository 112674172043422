import { useState, useEffect } from "react";
import { API_URL } from "models/constant";

export default function Expreience() {
  const [experiences, setExperiences] = useState([]);

  const getExperiences = async () => {
    await fetch(`${API_URL}/experiences.json`, {
      headers: {
        // 'Cache-Control': 'no-cache',
        // 'Pragma': 'no-cache',
        // 'Expires': '0',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setExperiences(data);
      });
  };

  useEffect(() => {
    getExperiences();
  }, []);

  return (
    <>
      <h2 className="tracking-[13px] dark:text-white font-bold my-16">
        EXPERIENCE
      </h2>

      <div className="space-y-6 mb-6">
        {experiences.map((e, i) => (
          <div
            className="flex flex-wrap md:flex-nowrap items-center gap-x-4 dark:text-white"
            key={i}
          >
            <p className="md:whitespace-nowrap opacity-75 text-xl w-full md:w-auto">
              {e.position}
            </p>
            <span className="block h-px md:w-full grow bg-gray-dark dark:bg-white opacity-10" />
            <p className="whitespace-nowrap opacity-75">{e.duration}</p>
          </div>
        ))}
      </div>
    </>
  );
}

import { Image } from 'components/common/image';
import { scrollTo } from 'helpers/scrollTo';

export default function About() {
  return (
    <section id="about" className="pt-24 md:pt-36 -mr-6 md:mr-8 relative z-10">
      <h1 className="text-5xl md:text-6xl font-bold mb-16 dark:text-white">Hello, <span className="animate-wave inline-block">👋</span></h1>

      <div className="text-xl space-y-8">
        <p className="font-semibold leading-[2.5rem] dark:text-white">Hi, my name is Iqbal. I am a frontend developer. I graduate from State University of Jakarta. I have been coding since high school, from there I got a lot of experience in developing websites.</p>
        <p className="dark:text-gray">I enjoy coding using HTML, CSS, Javascript and several frameworks that I enjoy such as React, Next, Tailwind, and other programming such as Node.js, Webpack, Express, JQuery, AJAX, and others. Familiar with git flow.</p>
        <p className="dark:text-gray">I’m always excited to connect with everyone and am open to speaking opportunities, so please don’t hesitate to get in touch. Anyway, thanks for viewing my portfolio!</p>
      </div>

      <div className="flex flex-col md:flex-row justify-between items-end md:items-start">
        <div className="flex flex-wrap items-center gap-4 my-10">
          <Image className="w-8" src='/assets/images/html.svg' alt="html logo" title="HTML"/>
          <Image className="w-8" src='/assets/images/css.svg' alt="css logo" title="CSS"/>
          <Image className="w-8" src='/assets/images/js.svg' alt="js logo" title="Javascript"/>
          <Image className="w-8" src='/assets/images/node.svg' alt="node logo" title="Node.js"/>
          <Image className="w-8" src='/assets/images/react.svg' alt="react logo" title="React"/>
          <Image className="w-8" src='/assets/images/next.svg' alt="next logo" title="Next"/>
          <Image className="w-8" src='/assets/images/tailwind.svg' alt="tailwind logo" title="TailwindCSS"/>
          <Image className="w-8" src='/assets/images/webpack.svg' alt="webpack logo" title="Webpack"/>
          <Image className="w-8" src='/assets/images/sass.svg' alt="sass logo" title="SASS/SCSS"/>
          <Image className="w-8" src='/assets/images/figma.svg' alt="figma logo" title="Figma"/>
          <Image className="w-8" src='/assets/images/git.svg' alt="git logo" title="Git"/>
          <Image className="w-8" src='/assets/images/php.svg' alt="php logo" title="PHP"/>
          <Image className="w-8" src='/assets/images/python.svg' alt="python logo" title="Python"/>
        </div>

        <div className="relative dark:text-white -mr-16">
          <svg viewBox="0 0 500 500" width="240px" className="animate-spin-slow">              
              <path d="M 250.001 457.286 C 135.573 457.286 42.715 364.428 42.715 250.001 C 42.715 135.573 135.573 42.715 250.001 42.715 C 364.428 42.715 457.286 135.573 457.286 250.001 C 457.286 364.428 364.428 457.286 250.001 457.286 Z" id="textcircle" fill="none" />
              <text className="tracking-[5px] text-4xl font-semibold" fill="currentColor">
                  <textPath href="#textcircle"> CONTACT ME • CONTACT ME • CONTACT ME • CONTACT ME •</textPath>
              </text>
          </svg>
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <div className="mouse-follow cursor-pointer p-8" onClick={e => scrollTo(e,'contact')}>
              <svg className="pointer-events-none" width="32" viewBox="0 0 54 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M27.041 0C27.8303 0 28.5872 0.32924 29.1453 0.915291C29.7034 1.50134 30.017 2.2962 30.017 3.125V76.8313L48.7416 57.1625C49.3004 56.5757 50.0583 56.2461 50.8485 56.2461C51.6388 56.2461 52.3967 56.5757 52.9555 57.1625C53.5143 57.7493 53.8282 58.5452 53.8282 59.375C53.8282 60.2048 53.5143 61.0007 52.9555 61.5875L29.148 86.5875C28.8716 86.8785 28.5432 87.1094 28.1816 87.267C27.8201 87.4245 27.4325 87.5056 27.041 87.5056C26.6496 87.5056 26.262 87.4245 25.9005 87.267C25.5389 87.1094 25.2105 86.8785 24.9341 86.5875L1.12658 61.5875C0.567776 61.0007 0.253845 60.2048 0.253845 59.375C0.253845 58.5452 0.567776 57.7493 1.12658 57.1625C1.68538 56.5757 2.44328 56.2461 3.23354 56.2461C4.02381 56.2461 4.7817 56.5757 5.34051 57.1625L24.0651 76.8313V3.125C24.0651 2.2962 24.3786 1.50134 24.9367 0.915291C25.4948 0.32924 26.2518 0 27.041 0V0Z" fill="currentColor"/>
              </svg>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}
import { useState } from 'react'

import Header from './Header'
import Footer from './Footer'
import Navigation from './Navigation'
import MouseCircle from './MouseCircle'

export default function Layout ({ children }) {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="transition min-h-screen antialiased relative overflow-hidden">
      <MouseCircle />
      <Header toggleShowMenu={() => setShowMenu(!showMenu)} />
      <Navigation showMenu={showMenu} setShowMenu={setShowMenu} />
      <main className="mx-8 md:mx-14">
        {children}
      </main>
      <Footer />
    </div>
  )
}
import { Button } from "components/common/button";
import { Image } from "components/common/image";
import { useEffect, useState } from "react";

export default function Blog() {

  const [featuredPosts, setFeaturedPosts] = useState([])

  const getBlog = async () => {
    const data = await fetch(process.env.REACT_APP_BLOG_URL + '/api/featured-post').then(res => res.json())
    setFeaturedPosts(data.featuredPosts)
  }

  useEffect(() => {
    getBlog()
  }, [])

  return (
    <section id="blog" className="pt-36 -mr-4 md:mr-8 mb-20">
      <h1 className="text-5xl md:text-6xl font-bold mb-16 dark:text-white">Blog 📰</h1>

      <div className="grid sm:grid-cols-2 md:grid-cols-4">
        {featuredPosts.slice(0,4).map((post, index) => (
          <div key={index} className="p-2 md:p-4">
            <a href={process.env.REACT_APP_BLOG_URL + `/${post.slug}`} className="block">
              <Image src={post.thumbnail} alt={post.title} title={post.title} className="w-full h-40 md:h-52 object-cover border dark:border-white" />
              <h2 className="text-lg font-semibold dark:text-white mt-2">{post.title}</h2>
            </a>
          </div>
        ))}
      </div>

      <div className="flex justify-center my-10">
        <Button href={process.env.REACT_APP_BLOG_URL} className="block">View All Posts</Button>
      </div>
    </section>
  )
}
import ProjectTab from './ProjectTab'
import Experience from './Experience'

export default function Project() {
  return (
    <section id="project" className="pt-36 mr-0 md:mr-8 relative">
      <span className="bg-[#0EF4CB] block absolute z-0 w-[380px] h-[380px] -top-32 -left-10 -translate-x-3/4 md:-translate-x-1/2 rounded-full blur-3xl opacity-50" />

      <div className="relative z-10">
        <h1 className="text-5xl md:text-6xl font-bold mb-16 dark:text-white">Project 🖥️</h1>
        <ProjectTab />
        <Experience />
      </div>
    </section>
  )
}
import { useState, useRef } from 'react'
import PropTypes from 'prop-types';

import { useOnClickOutside } from 'helpers/useOnClickOutsite'

const Dropdown = ({ href, text, children }) => {
  const [show, setShow] = useState(false)
  const elm = useRef()
  useOnClickOutside(elm, () => setShow(false))

  return (
    <div ref={elm} className="flex items-center border border-2 border-black dark:border-white dark:text-white opacity-50 hover:opacity-100 transition">
      <a href={href} target="_blank" rel="noreferrer" className="font-semibold px-6 py-2">{text}</a>
      {children && (
        <div className="border-l-2 border-black dark:border-white h-full relative select-none">
          <button className="cursor-pointer py-2 px-1" onClick={() => setShow(!show)} aria-label="Collapsible">
            <svg className={`inline transition ${show ? 'rotate-180' : ''}`} xmlns="http://www.w3.org/2000/svg" width="24" fill="currentColor" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
          </button>
          {show && (
            <div className="absolute border border-2 border-black dark:border-white shadow-lg shadow-black/30 dark:shadow-white/30 top-full right-0 mt-2">
              {children}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

Dropdown.propTypes = {
  /**
   * url link
   */
  href: PropTypes.string.isRequired,
  /**
   * text dropdown main display
   */
  text: PropTypes.string.isRequired,
  /**
   * children element for collapsing
   */
  children: PropTypes.element,
};

export { Dropdown };
import { useState, useRef } from "react";

import { Modal } from "components/common/modal";

import { mouseHover } from "helpers/mouseEventTriger";
import { Image } from "components/common/image";

export default function ImageProject({ data }) {
  const [showModal, setShowModal] = useState(false);
  const [style, setStyle] = useState(null);

  const img = useRef();

  const initStyle = () => {
    const rect = img.current.getBoundingClientRect();
    setStyle({
      content: {
        opacity: "0",
      },
      img: {
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height,
        boxShadow: "none",
      },
    });
  };

  const handleClick = () => {
    setShowModal(true);
    initStyle();
    setTimeout(() => {
      setStyle(null);
    }, 300);
  };

  return (
    <>
      <Image
        tabIndex={0}
        src={data.img}
        ref={img}
        alt={data.name}
        onClick={handleClick}
        onLoad={(e) => mouseHover(e.target)}
        className="w-[50vw] md:w-[320px] border hover:scale-110 transition mouse-circle-hover"
      />
      <Modal isOpen={showModal} handleClose={() => setShowModal(false)}>
        <div className="flex flex-col md:flex-row items-start pointer-events-none">
          <Image
            src={data.img}
            alt={data.name}
            style={style?.img}
            className="w-[80vw] max-w-[460px] pointer-events-auto shadow-flat shadow-black/10 border object-left relative transition-all duration-1000 top-[10px] left-[10px] z-10"
          />
          <div
            className="grow pointer-events-auto delay-1000 opacity-100 transition-all bg-white dark:bg-black shadow-xl dark:shadow-white/20 dark:text-white duration-1000 p-10 pt-14 ml-10 mr-2 md:mr-10 -mt-2 md:mt-40 md:-ml-8 md:pl-28 md:min-h-[400px]"
            style={style?.content}
          >
            <div className="flex flex-wrap items-center gap-x-6">
              <a
                className="inline-block bg-black/50 dark:bg-white/50"
                href={data.link}
                target="_blank"
                rel="noreferrer"
              >
                <span className="block bg-black dark:bg-white text-white dark:text-black px-4 py-1 -translate-x-2 -translate-y-2 hover:translate-x-0 hover:translate-y-0 transition-all">
                  View ↗
                </span>
              </a>
              <h1 className="font-bold text-2xl">{data.name}</h1>
            </div>
            <div
              className="py-6 leading-8"
              dangerouslySetInnerHTML={{ __html: data.desc }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

import { useEffect, useRef } from 'react'

import { mouseEventTriger } from 'helpers/mouseEventTriger'

export default function MouseCircle() {

  const mouseCircleElement = useRef(null)

  useEffect(() => {
    if (window.innerWidth > 768) {
      const { TweenLite } = window;

      let mouse = {x: 0, y: 0},
      pos = {x: 0, y: 0},
      ratio = 0.5, //delay follow cursor
      active= false,
      mouseCircle = mouseCircleElement.current;

      TweenLite.set(mouseCircle, { xPercent: -50, yPercent: -50 });
      TweenLite.ticker.addEventListener("tick", () => {
        if (!active) {
          pos.x += (mouse.x - pos.x) * ratio;
          pos.y += (mouse.y - pos.y) * ratio;

          TweenLite.set(mouseCircle, { x: pos.x, y: pos.y });
        }
      });

      document.onmousemove = (e) => {
        mouse.x = e.clientX;
        mouse.y = e.clientY;
      }

      mouseEventTriger(window, document, mouseCircle, {
        active,
        pos
      });
    }
  },[])

  return (
    <div ref={mouseCircleElement} id="mouse-circle" className="hidden md:flex justify-center items-center text-center fixed z-[99] w-16 h-16 rounded-full border-2 -translate-x-1/2 -translate-y-1/2 pointer-events-none transition-all" />
  )
}
import { useState, useEffect, useRef } from 'react'

export default function ProjectTab({
  onChange
}) {
  const [selected, setSelected] = useState('all');
  const line = useRef(null);

  const tabList = ['all', 'profesional', 'personal']

  const handleClick = (e, event) => {
    setSelected(e);
    if (typeof onChange === 'function') onChange(e);
    line.current.style.width = event.target.clientWidth + 'px'
    line.current.style.left = event.target.offsetLeft + 'px'
  }

  useEffect(() => {
    const widthInit = document.querySelector('.tab button').clientWidth || 0;
    line.current.style.width = widthInit + 'px'
  },[])

  return (
    <div className="tab flex gap-3 md:gap-8 text-xl relative mb-6">
      {tabList.map((e,i) => (
        <button
        key={i}
        className={`font-semibold dark:text-white px-1 md:px-3 ${e === selected ? 'active' : ''}`}
        onClick={(event) => handleClick(e, event)}
        >{e}</button>
      ))}
      <span ref={line} className="block absolute bg-black dark:bg-white h-1 bottom-0 left-0 mb-1 transition-all" />
    </div>
  )
}
import { forwardRef } from "react";
import { LazyImage } from "react-lazy-images";

export const Image = forwardRef(({ ...props }, componentRef) => {
  return (
    <LazyImage
      {...props}
      placeholder={({ ref }) => <div ref={ref} />}
      actual={({ imageProps }) => (
        // eslint-disable-next-line
        <img ref={componentRef} {...imageProps} />
      )}
    />
  );
});

export function mouseEventTriger(window, document, mouseCircle, payload) {
  const { TweenMax } = window;

  document.querySelectorAll('.mouse-follow').forEach(elm => {
    elm.onmouseenter = () => {
      TweenMax.to(mouseCircle, 0.3, { scale: 2 }); //scale mouseCircle
      TweenMax.to(mouseCircle, 0.3, { borderWidth: '.5px' });
      if (elm.classList.contains('no-circle')) mouseCircle.style.opacity = 0;
      payload.active = true;
    }
    elm.onmousemove = (e) => {
      parallaxCursor(e, elm, 1); //magnetic ball = low number is more attractive
      parallaxIt(e, elm);
    }
    elm.onmouseleave = () => {
      TweenMax.to(mouseCircle, 0.3, { scale: 1 }); //scale mouseCircle
      TweenMax.to(mouseCircle, 0.3, { borderWidth: '2px' });
      TweenMax.to(elm, 0.3, { x: 0, y: 0 });
      if (elm.classList.contains('no-circle')) mouseCircle.style.opacity = 1;
      payload.active = false;
    }
  });

  // document.querySelectorAll('.mouse-circle-hover').forEach(elm => {
  //   elm.onmouseenter = () => {
  //     mouseCircle.style.background = '#fff';
  //     mouseCircle.style.borderWidth = '1px';
  //     mouseCircle.innerText = 'Click\nMe';
  //     TweenMax.to(mouseCircle, 0.3, { scale: 2 }); //scale mouseCircle
  //   }
  //   elm.onmouseleave = () => {
  //     mouseCircle.style.removeProperty('background');
  //     mouseCircle.style.removeProperty('border');
  //     mouseCircle.innerText = '';
  //     TweenMax.to(mouseCircle, 0.3, { scale: 1 }); //scale mouseCircle
  //   }
  // })

  function parallaxIt(e, parent, movement = 80) {
    let boundingRect = parent.getBoundingClientRect();
    let relX = e.clientX - boundingRect.left;
    let relY = e.clientY - boundingRect.top;

    TweenMax.to(e.target, 0.3, {
      x: (relX - boundingRect.width / 2) / boundingRect.width * movement,
      y: (relY - boundingRect.height / 2) / boundingRect.height * movement,
      ease: window.Power2.easeOut
    });
  }

  function parallaxCursor(e, parent, movement) {
    let rect = parent.getBoundingClientRect();
    let relX = e.clientX - rect.left;
    let relY = e.clientY - rect.top;
    payload.pos.x = rect.left + rect.width / 2 + (relX - rect.width / 2) / movement;
    payload.pos.y = rect.top + rect.height / 2 + (relY - rect.height / 2) / movement;
    TweenMax.to(mouseCircle, 0.3, { x: payload.pos.x, y: payload.pos.y });
  }
}

export function mouseHover(elm) {
  const { TweenMax } = window;
  const mouseCircle = document.getElementById('mouse-circle');

  elm.onmouseenter = () => {
    mouseCircle.style.background = '#fff';
    mouseCircle.style.borderWidth = '1px';
    mouseCircle.innerText = 'See\nProject';
    TweenMax.to(mouseCircle, 0.3, { scale: 2 }); //scale mouseCircle
  }
  elm.onmouseleave = () => {
    mouseCircle.style.removeProperty('background');
    mouseCircle.style.removeProperty('border');
    mouseCircle.innerText = '';
    TweenMax.to(mouseCircle, 0.3, { scale: 1 }); //scale mouseCircle
  }
}
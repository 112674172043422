import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types';
import ReactPortal from 'components/portal'

const Modal = ({ children, isOpen, handleClose }) => {
  const modalBG = useRef();

  useEffect(() => {
    const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [handleClose]);

  useEffect(() => {
    isOpen
      ? document.body.classList.add('modal')
      : document.body.classList.remove('modal')
    return () => document.body.classList.remove('modal')
  }, [isOpen])

  if (!isOpen) {
    return null;
  }

  const handleClickBG = e => {
    if (e.target === modalBG.current) {
      handleClose();
    }
  }

  return (
    <ReactPortal wrapperId="modal">
      <div ref={modalBG} className="fixed top-0 left-0 w-full h-screen overflow-y-auto bg-[#eee]/95 dark:bg-[#333]/95 z-[99]" onClick={handleClickBG}>
        <button onClick={handleClose} className="absolute text-3xl top-4 right-4 dark:text-white">x</button>
        {children}
      </div>
    </ReactPortal>
  );
}

Modal.propTypes = {
  /**
   * children element
   */
  children: PropTypes.element,
  /**
   * isOpen
   */
  isOpen: PropTypes.bool,
  /**
   * callback when closed
   */
  handleClose: PropTypes.func,
};

export {Modal};
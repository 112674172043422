import { Image } from "components/common/image";

export default function Contact() {
  return (
    <section id="contact" className="pt-36 -mr-4 md:mr-8 mb-20">
      <h1 className="text-5xl md:text-6xl font-bold mb-16 dark:text-white">Contact 📞</h1>

      <div className="flex flex-col md:flex-row items-center justify-around dark:text-white">
        <a className="underline px-16 py-24 font-bold text-3xl mouse-follow" href="mailto:iqbal132nurkholis@gmail.com">Email</a>
        <span className="text-[100px] hidden md:block">/</span>
        <span className="block md:hidden">or</span>
        <a className="underline px-16 py-24 font-bold text-3xl mouse-follow" href="https://www.linkedin.com/in/iqbalnurkholis">Linkedin</a>
      </div>

      <div className="flex items-center gap-3 my-8 dark:text-white">
        <p className="">You can also find me in:</p>
        <a href="https://github.com/iqbl-nrkhls" aria-label="Github">
          <Image className="w-6 dark:invert" src="assets/images/github.svg" alt="github logo" title="Github" />
        </a>
        <a href="https://www.codewars.com/users/iqbl-nrkhls" aria-label="Codewars">
          <Image className="w-6 dark:invert" src="assets/images/codewars.svg" alt="codewars logo" title="Codewars" />
        </a>
        <a href="https://www.frontendmentor.io/profile/iqbl-nrkhls" aria-label="Frontend mentor">
          <Image className="w-6 dark:invert" src="assets/images/frontendmentor.svg" alt="frontend mentor logo" title="Frontend Mentor" />
        </a>
      </div>
    </section>
  )
}
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef } from 'react'

import { useDarkMode } from 'helpers/darkMode'
import { useOnClickOutside } from 'helpers/useOnClickOutsite'
import { scrollTo } from 'helpers/scrollTo'

export default function Navigation({ showMenu, setShowMenu }) {
  const [theme, toggleTheme] = useDarkMode();
  const menu = useRef();
  useOnClickOutside(menu, () => setShowMenu(false))

  useEffect(() => {
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [])

  return (
    <nav ref={menu} className={`w-14 h-screen fixed top-0 right-0 border-l border-black dark:border-white text-verticaly flex items-center z-50 bg-gray dark:bg-black md:!bg-transparent transition md:bg-transparent  md:translate-x-0 overflow-x-hidden
      ${showMenu ? '' : ' translate-x-full'}
      `}
    >
      <button className="mt-14" onClick={() => toggleTheme()} aria-label="dark mode toggler">
        {theme === 'dark'
          ? <img className="w-6 h-6 select-none" src="assets/images/sun.svg" alt="" />
          : <img className="w-6 h-6 select-none" src="assets/images/moon.svg" alt="" />
        }
      </button>
      <div className="grow flex items-center justify-around dark:text-white">
        <a className="p-4 nav-list mouse-follow no-circle" href="#" onClick={e => scrollTo(e, 'about')}>About</a>
        <a className="p-4 nav-list mouse-follow no-circle" href="#" onClick={e => scrollTo(e, 'project')}>Project</a>
        <a className="p-4 nav-list mouse-follow no-circle" href="#" onClick={e => scrollTo(e, 'contact')}>Contact</a>
      </div>
    </nav>
  )
}
import PropTypes from "prop-types";

const Button = ({ children, className, href, ...props }) => {
  return href ? (
    <a
      href={href}
      {...props}
      className={`font-semibold px-6 py-2 border border-2 border-black dark:border-white dark:text-white ${className || ''}`}
      >
      {children}
    </a>
  ) : (
    <button
      {...props}
      className={`font-semibold px-6 py-2 border border-2 border-black dark:border-white dark:text-white ${className || ''}`}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  /**
   * target limnk
   */
  href: PropTypes.string,
  /**
   * text display
   */
  children: PropTypes.string.isRequired,
  /**
   * class name
   */
  className: PropTypes.string,
};

export { Button };

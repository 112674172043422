import TypeIt from 'typeit-react'
import { Dropdown } from 'components/common/button'

export default function Cover() {
  return (
    <section id="home" className="pt-16 md:pt-36 relative">
      <div className="flex flex-col md:flex-row items-start md:items-center gap-6 my-10 mr-4">
        <span className="h-px w-10 bg-gray-dark dark:bg-white" />
        <p className="tracking-[0.17rem] text-sm dark:text-white">PORTFOLIO OF IQBAL NURKHOLIS</p>
        <div className="md:grow" />
        <Dropdown href="resume.pdf" text="Download Resume">
          <>
            <a target="_blank" href="resume-eng.pdf" className="block px-3 py-1 hover:bg-black/10 dark:hover:bg-gray/10">English</a>
            <a target="_blank" href="resume.pdf" className="block px-3 py-1 hover:bg-black/10 dark:hover:bg-gray/10">Bahasa</a>
          </>
        </Dropdown>
      </div>

      <h1 className="text-right dark:text-white text-[68px] md:text-[220px] leading-[1.3em] md:leading-[0.75em] tracking-[-0.1em] -mx-7 md:pr-6 my-16 md:my-28 relative z-10">
        <TypeIt
        options={{
          cursorChar: '',
          speed: 500,
          deleteSpeed: 500
        }}
        getBeforeInit={(instance) => {
          instance.type('FRONTEND<br/>DEPELOPER').pause(750).move(-6).delete(1).pause(500).type("V");
          return instance;
        }}/>
      </h1>
      <span className="bg-[#FAFF00] block absolute z-0 w-[640px] h-[640px] top-3/4 right-0 translate-x-3/4 md:translate-x-1/4 rounded-full blur-3xl opacity-50" />
    </section>
  )
}
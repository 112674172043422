import Layout from 'components/layout'

import Cover from 'components/section/cover'
import About from 'components/section/about'
import Project from 'components/section/project'
import Blog from 'components/section/blog'
import Contact from 'components/section/contact'

export default function App() {
  return (
    <Layout>
      <Cover />
      <About />
      <Project />
      <Blog />
      <Contact />
    </Layout>
  );
}
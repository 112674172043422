import { useState, useEffect } from "react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import { API_URL } from "models/constant";
import ProjectTabHeader from "./ProjectTabHeader";
import ImageProject from "./ImageProject";

export default function ProjectTab() {
  const [filter, setFilter] = useState("");
  const [projects, setProjects] = useState([]);

  const getProjects = async () => {
    await fetch(`${API_URL}/projects.json`, {
      headers: {
        // 'Cache-Control': 'no-cache',
        // 'Pragma': 'no-cache',
        // 'Expires': '0',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setProjects(data);
      });
  };

  useEffect(() => {
    getProjects();
  }, []);

  const handleFilterChange = (value) => {
    value === "all" ? setFilter("") : setFilter(value);
  };

  return (
    <>
      <ProjectTabHeader onChange={(value) => handleFilterChange(value)} />

      <SimpleBar className="overflow-auto -mx-8 md:-ml-14 p-8">
        <div className="w-max grid grid-rows-2 grid-flow-col-dense -mr-8">
          {projects
            .filter((e) => e.type.includes(filter))
            .map((e, i) => (
              <div
                key={i}
                className="
            nchild-1/6:scale-110
            hover:relative hover:z-10
            nchild-2/6:-translate-y-2
            nchild-3/6:-translate-y-4
            nchild-4/6:translate-x-2
            nchild-5/6:translate-x-2 nchild-5/6:translate-y-2
            nchild-6/6:scale-90 nchild-6/6:translate-x-2 nchild-6/6:-translate-y-2
            "
              >
                <ImageProject data={e} />
              </div>
            ))}
        </div>
      </SimpleBar>
      <div className="w-full flex justify-end gap-4 dark:text-white p-4">
        Scroll for more
        <svg
          className="pointer-events-none -rotate-90"
          width="16"
          viewBox="0 0 54 88"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.041 0C27.8303 0 28.5872 0.32924 29.1453 0.915291C29.7034 1.50134 30.017 2.2962 30.017 3.125V76.8313L48.7416 57.1625C49.3004 56.5757 50.0583 56.2461 50.8485 56.2461C51.6388 56.2461 52.3967 56.5757 52.9555 57.1625C53.5143 57.7493 53.8282 58.5452 53.8282 59.375C53.8282 60.2048 53.5143 61.0007 52.9555 61.5875L29.148 86.5875C28.8716 86.8785 28.5432 87.1094 28.1816 87.267C27.8201 87.4245 27.4325 87.5056 27.041 87.5056C26.6496 87.5056 26.262 87.4245 25.9005 87.267C25.5389 87.1094 25.2105 86.8785 24.9341 86.5875L1.12658 61.5875C0.567776 61.0007 0.253845 60.2048 0.253845 59.375C0.253845 58.5452 0.567776 57.7493 1.12658 57.1625C1.68538 56.5757 2.44328 56.2461 3.23354 56.2461C4.02381 56.2461 4.7817 56.5757 5.34051 57.1625L24.0651 76.8313V3.125C24.0651 2.2962 24.3786 1.50134 24.9367 0.915291C25.4948 0.32924 26.2518 0 27.041 0V0Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </>
  );
}
